<template>
  <v-container fluid>
    <!---BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumb.items" />
    <!--EOC-->
    <BreadHeader
      :title="`New ${
        childModel.name[parentModel.key]
          ? childModel.name[parentModel.key].singular
          : childModel.name.singular
      }`"
    />
    <!---BOC:[form]-->
    <BreadForm
      :isLoading="apiReadParent.isLoading"
      ref="form"
      action="add"
      :api="api"
      :model="childModel"
      :parentModel="parentModel"
    />
    <!--EOC-->
  </v-container>
</template>

<script>
//BOC:[api]
import Api from "@/objects/api";
//EOC
//BOC:[breadcrumb]
import Breadcrumb from "@/objects/breadcrumb";
//EOC
//BOC:[model]
import Model from "@/objects/model";
//EOC
//BOC:[service]
import Service from "@/objects/service";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    services: (state) => state.service.data.services,
  }),
  props: {
    //
  },
  data: () => ({
    //BOC:[api]
    api: new Api(),
    //EOC
    //BOC:[apiReadParent]
    apiReadParent: new Api(),
    //EOC
    //BOC:[breadcrumbs]
    breadcrumb: new Breadcrumb(),
    //EOC
    //BOC:[childModel]
    childModel: new Model(),
    //EOC
    //BOC:[parentModel]
    parentModel: new Model(),
    parentModelId: null,
    //EOC
    //BOC:[service]
    service: new Service(),
    //EOC
  }),
  created() {
    //BOC:[parentModel]
    this.parentModel.getByKey(this.$route.params.parentModelKey);
    this.parentModelId = this.$route.params.parentModelId;
    //EOC
    //BOC:[childModel]
    this.childModel.getByKey(this.$route.params.childModelKey);
    //EOC
    //BOC:[service]
    if (this.parentModel.serviceKey)
      this.service.set("key", this.parentModel.serviceKey);
    //EOC
    //BOC:[breadcrumbs]
    this.breadcrumb.addForOperationChildAdd({
      services: this.services,
      parentModel: this.parentModel,
      parentModelId: this.parentModelId,
      childModel: this.childModel,
    });
    //EOC
    //BOC:[apiReadParent]
    this.apiReadParent.setUrl(
      `${this.$service[this.service.key]}/v1/en/console/model/${
        this.parentModel.key
      }/${this.parentModelId}`
    );
    this.apiReadParent.setCallbackCompleted((resp) => {
      var data = {
        [this.$_ucfirst(this.parentModel.key)]:
          resp
      };
      this.$refs.form.setData(data);
    });
    this.apiReadParent.fetch();
    //EOC
    //BOC:[api]
    this.api.setMethod(`POST`);
    this.api.setUrl(
      `${this.$service[this.service.key]}/v1/en/console/model/${
        this.childModel.key
      }/add`
    );
    //
    if (this.childModel.add.customApiPath) {
      this.api.setUrl(
        `${this.$service[this.service.key]}${this.childModel.add.customApiPath}`
      );
    }
    //
    this.api.setCallbackCompleted(() => {
      this.$router.push({
        name: "PageServiceModelRead",
        query: {
          tab: this.childModel.key,
        },
        params: {
          serviceKey: this.parentModel.serviceKey,
          modelKey: this.parentModel.key,
          modelId: this.parentModelId,
        },
      });
      this.$store.dispatch("showMessage", "Created successfully.");
    });
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>